<template>
  <div class="layout-padding q-ma-md">
    <m-group-title label="Vehicle Groups" />
    <q-item-label caption style="margin-top: 0.5rem;">
      Enable/disable vehicle groups for onward travel searches
    </q-item-label>

    <q-card bordered class="q-pa-md flex-card" style="margin-top: 1.5rem;">
      <div class="row items-center no-grow q-gutter-md">
        <div v-for="(vehicleType, index) in vehicleTypes" :key="vehicleType.key" class="row items-center no-grow q-gutter-sm">
          <q-item>
            <q-item-section>
              <q-item-label>{{ vehicleType.label }}</q-item-label>
            </q-item-section>
            <q-item-section avatar>
              <q-toggle v-model="vehicleType.value" />
            </q-item-section>
          </q-item>
          <q-separator v-if="index < vehicleTypes.length - 1" vertical inset />
        </div>
        <q-item-section>
          <q-btn label="Save Groups" color="primary" @click="saveVehicleGroups" />
        </q-item-section>
      </div>
    </q-card>
  </div>
</template>
<script>
import { MGroupTitle } from 'components'
import store from 'store'
import i18n from 'i18n'
import loading from 'utils/loading'
import { getOnwardTravelConfig, updateOnwardTravelConfig } from 'api/organisations'
import { handleErrors } from 'utils/utils'
import { Notify } from 'quasar'

export default {
  name: 'OnwardTravelConfig',
  components: {
    MGroupTitle
  },
  data () {
    return {
      vehicleTypes: [
        { key: 'taxi', label: 'Taxi', value: false },
        { key: 'exec', label: 'Exec', value: false },
        { key: 'mpv', label: 'MPV', value: false },
        { key: 'wav', label: 'WAV', value: false },
        { key: 'other', label: 'Other', value: false }
      ]
    }
  },
  computed: {
    orgId () {
      return this.$route.params.organisation
    }
  },
  async beforeMount () {
    const partner = store.getters.partner
    loading.start({
      message: i18n.t('loading.getting.onward_travel_config'),
      partnerSlug: partner.slug,
      spinnerColor: partner.hex
    })

    try {
      const { data } = await getOnwardTravelConfig(this.orgId)

      const { vehicle_groups } = data

      this.vehicleTypes = this.vehicleTypes.map(vehicleType => {
        const { key } = vehicleType
        const value = vehicle_groups.includes(key)
        return { ...vehicleType, value }
      })
      loading.stop()
    } catch (err) {
      handleErrors(err)
      loading.stop()
    }
  },
  methods: {
    async saveVehicleGroups () {
      try {
        await updateOnwardTravelConfig(this.orgId, {
          vehicle_groups: this.vehicleTypes.filter(vehicleType => vehicleType.value).map(vehicleType => vehicleType.key)
        })
        Notify.create({
          message: i18n.t('organisation.updated'),
          color: 'positive',
          icon: 'mdi-check'
        })
      } catch (err) {
        handleErrors(err)
      }
    }
  }
}
</script>

<style>
  .no-grow {
    flex-grow: 0;
    flex-shrink: 0;
  }
  .flex-card {
    display: inline-flex;
    flex-direction: column;
  }
</style>
